<template>
    <Modal size="small" :title="$t('expert.expertDetail')">
        <template #modalContent>
       
            <div class="s-square-300 s-mlr-auto">
                <img v-if="!data.profile" :src="require('@/assets/image-profile.png')"/>
                <img v-else :src="data.profile"/>
            </div>
            <div>
                <h5>{{data.name}}</h5>
                <div class="s-mt--16 s-center-flex s-wrap">
                    <div v-for="(j, index) in data.expertises" :key="`ed-${index}`" class="s-tag-secondary s-plr-10 s-mr-5">
                        <p class="s-text-white" style="font-size: 12px">{{j.name}}</p>
                    </div>
                </div>
                <div class="s-center-flex ">
                    <div v-if="data.position" class="s-center-flex s-column-2">
                        <div class="s-square-30">
                            <img :src="require('@/assets/icons/primary/icon-briefcase.svg')"/>
                        </div>
                        <p style="font-size: 12px">{{data.position}}</p>
                    </div>
                    <div v-if="data.email" class="s-center-flex s-column-2">
                        <div class="s-square-30">
                            <img :src="require('@/assets/icons/primary/icon-mail.svg')"/>
                        </div>
                        <p style="font-size: 12px">{{data.email}}</p>
                    </div>
                </div>
                <div class="s-center-flex">
                    <div v-if="data.rating != null" class="s-center-flex s-column-2">
                        <div class="s-square-30">
                            <img :src="require('@/assets/icons/icon-star.svg')"/>
                        </div>
                        <p style="font-size: 12px">{{data.rating}} /10</p>
                    </div>
                    <div v-if="data.profile_url"  class="s-center-flex s-column-2">
                        <div class="s-square-30">
                            <img :src="require('@/assets/icons/others/icon-globe.svg')"/>
                        </div>
                        <a :href="data.profile_url" rel="noreferrer noopener"  target="_blank">
                            <ButtonCustom type="textOnly" padding="0">
                                Biografi Expert
                            </ButtonCustom>
                        </a>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
import Modal from '@/components/helper/Modal'
import ButtonCustom from '@/components/helper/Button'
export default {
    components: {
        Modal,
        ButtonCustom
    },
    props: {
        data: {
            default: {},
            required: true
        }
    }
}
</script>