<template>
  <div>
    <content-template
      :menuName="$t('expertMatching.title')"
      :newButton="role_type === 'stakeholder' || role_type === 'public'"
      @moveToForm="moveToForm()"
    >
      <template #table-header>
        <span
          :class="`s-mb-30 ${isMobile ? 's-fullWidth' : 's-flex s-mt--65'}`"
        >
          <Input
            class="s-ml-auto"
            label=""
            :placeholder="$t('general.search')"
            v-model="request.search"
            @keyup="setTime()"
            @keydown="clearTime()"
            @keydown.tab="setTime()"
            @keyup.tab="clearTime()"
            @keypress.enter="getData()"
          />
        </span>
      </template>

      <template #table>
        <TableCustom
          :columns="columns"
          :request="request"
          :fetchData="fetchData"
          :data="rows"
          @setSortBy="setSortBy"
          @setShow="setShow"
          @getData="getData"
          @mouseleave="popup = null"
          @togglePopup="togglePopup"
        >
          <template #index="props">
            {{ curNumber(props.index) }}
          </template>

          <template #start_date="props">
            {{ convertDate(props.row.start_date) }}
          </template>

          <template #end_date="props">
            {{ convertDate(props.row.end_date) }}
          </template>

            <template #expertise="props">
            <span
              class="s-text-black"
              v-for="(e, i) in props.row.expertise_detail"
              :key="`expert-${i}`"
            >
              {{ e.name }}
              <span
                class="s-text-black"
                v-if="i < props.row.expertise_detail.length - 1"
                >,
              </span>
            </span>
          </template>

          <template #action="props">
            <div class="r-menu">
              <img
                class="r-isClickAble"
                :src="require('@/assets/icons/others/more.svg')"
               
              />
              <div
                class="r-menu-items"
                v-if="popup == curNumber(props.index)"
                @mouseleave="popup = -1"
              >
                <div
                  class="r-menu-item s-center-flex"
                  @click.prevent="setDetail(props.row)"
                >
                  Detail
                </div>
                <div
                  class="r-menu-item s-center-flex"
                  @click.prevent="setUpdate(props.row)"
                  v-if="props.row.status != 'Closed' && (role_type != 'admin' && role_type !='superadmin')"
                >
                  {{ $t("general.update") }}
                </div>
                <div
                  class="r-menu-item s-center-flex"
                  @click.prevent="setSignedUpExperts(props.row)"
                >
                  {{ $t("expertMatching.signedUpExpert") }}
                </div>
                <div
                  v-if="props.row.status == 'On Progress' && (role_type != 'admin' && role_type !='superadmin')"
                  class="r-menu-item s-center-flex"
                  @click.prevent="openDialog('showModalRating', props.row)"
                  
                >
                  {{ $t("general.finish") }}
                </div>
                <div
                  v-if="props.row.status == 'Closed'"
                  class="r-menu-item s-center-flex"
                  @click.prevent="setUploadFiles(props.row)"
                >
                  {{ $t("general.uploadFile") }}
                </div>
                <div
                  class="r-menu-item s-text-red s-center-flex"
                  @click.prevent="openDialog('showModalDelete', props.row)"
                  v-if="props.row.status != 'Closed'"
                >
                  {{ $t("general.delete") }}
                </div>
              </div>
            </div>
          </template>
        </TableCustom>
      </template>
    </content-template>

    <Modal v-if="modal == 'showModalDelete'" :title="$t('general.warning')">
      <template #modalContent>
        <p>{{ $t("general.modalDelete") }}</p>

        <div class="s-mtb-20 s-flex">
          <div class="s-ml-auto">
            <Button color="error" @click.prevent="onDelete">
              {{ $t("general.delete") }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>

    <Modal v-if="modal == 'showModalRating'" title="Give Rating">
      <template #modalContent>
        <form>
          <div class="s-flex" style="align-items: flex-end">
            <Input
              type="number"
              v-model="edited_item.score"
              label="Score"
              :error="validation.score"
              :min="0"
              :max="10"
            />
            <b
              ><span class="s-ml-10"> {{ $t("expert.outOf") }} </span>
            </b>
          </div>
        </form>
        <div class="s-mtb-20 s-flex">
          <div class="s-ml-auto">
            <Button color="error" @click.prevent="onFinish()">
              {{ $t("general.submit") }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import tableMixins from "@/mixins/tableMixins";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";
import { convertDate } from "@/common/timeformat";

export default {
  components: { ContentTemplate },
  mixins: [tableMixins, form],
  data() {
    return {
      columns: [
        {
          label: "No",
          fieldName: "index",
          isSortable: false,
          class: "r-table-firstColumn",
        },
        {
          label: "general.title",
          fieldName: "title",
          isSortable: true,
          class: "",
        },
        {
          label: "general.location",
          fieldName: "location",
          isSortable: true,
          class: "",
        },
        {
          label: "general.startDate",
          fieldName: "start_date",
          isSortable: true,
          class: "",
        },
        {
          label: "general.endDate",
          fieldName: "end_date",
          isSortable: true,
          class: "",
        },
        {
          label: "sidebar.expertise",
          fieldName: "expertise",
          isSortable: true,
          class: "",
        },
        {
          label: "Status",
          fieldName: "status",
          isSortable: true,
          class: "",
        },
        {
          label: "general.action",
          isSortable: false,
          fieldName: "action",
          class: "r-table-actionColumn",
        },
      ],
      current_id: "",
      edited_item: {
        score: "",
      },
      validation: {
        score: "",
      },
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    ...mapGetters({
      rows: "expertmatching/getExpertMatching",
      role_type: "auth/getRole",
      request: "expertmatching/getRequest",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    modal() {
      return this.$store.getters["getModal"];
    },
  },
  methods: {
    ...mapActions({
      setExpertMatching: "expertmatching/setExpertMatchingData",
      setModal: "setModal",
      delete_expertmatching: "expertmatching/onDelete",
      giveRating: "expert/giveRating",
      setExpertMatchingSingle: "expertmatching/setExpertMatchingSingleData",
    }),
    async initData() {
      await this.getData();
      await this.setExpertMatchingSingle(this.$route.params.id);
    },
    async onDelete() {
      await this.delete_expertmatching(this.current_id);
      this.setModal(false);
      this.setExpertMatching();
    },

    moveToForm() {
      this.$router.push(
        this.$translate({
          name: "New Expert Matching",
          params: { inputType: "New" },
        })
      );
    },

    async getData(type = 0) {
      this.fetchData = true;
      if (type === 0) this.request.page = 1;
      else this.request.page += type;
      await this.setExpertMatching();
      this.fetchData = false;
      // const response = this.getResponse("BRRD");
      // if (response.status !== 1) {
      //   this.showSnackbar({
      //     type: "error",
      //     text: response.msg,
      //   });
      // }
    },

    async onFinish() {
      this.checkEmptyField(
        this.edited_item,
        this.validation,
        false,
        null,
        null
      );

      if (this.edited_item.score < 0 || this.edited_item.score > 10) {
        this.showSnackbar({
          type: "error",
          text: this.$t("expert.scoreMustBe"),
        });
      }

      if (
        !this.empty_field &&
        this.edited_item.score >= 0 &&
        this.edited_item.score <= 10
      ) {
        const data = {
          id: this.current_id,
          rating: this.edited_item.score,
        };
        await this.giveRating(data);
        this.initData();
        this.setModal(null);
      }
    },

    setDetail(data) {
      this.$router.push(
        this.$translate({
          name: "Expert Matching Detail",
          params: {
            id: data.id,
          },
        })
      );
    },

    setUpdate(data) {
      this.$router.push(
        this.$translate({
          name: "Edit Expert Matching",
          params: {
            inputType: "Edit",
            id: data.id,
          },
        })
      );
    },

    setSignedUpExperts(data) {
      this.$router.push(
        this.$translate({
          name: "Expert Matching Expert List",
          params: { id: data.id },
        })
      );
    },

    setGiveRating(data) {
      this.$router.push(
        this.$translate({
          name: "New Expert Matching Rating",
          params: { inputType: "New", id: data.id },
        })
      );
    },

    setUploadFiles(data) {
      this.$router.push(
        this.$translate({
          name: "Expert Matching Upload Files",
          params: { id: data.id },
        })
      );
    },

    openDialog(state, data) {
      this.setModal(state);
      this.current_id = data.id;
    },
    convertDate(value) {
      return convertDate(value);
    },
  },
};
</script>
