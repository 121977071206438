<template>
    <div class="s-bg-white">
        <div :class="isMobile? '':'s-ht-400-min'">
            <div class="s-content-bg s-flex">
                <div :class="`s-main-container ${isMobile? 's-fullWidth s-ptb-20':'s-mtb-auto'}`">
                <div>
                    <h2 v-if="!isMobile" class="s-text-white">{{$t('expert.expertMatching')}}</h2>
                    <h5 v-else class="s-text-white s-text-center">{{$t('expert.expertMatching')}}</h5>
                </div>
                </div>
            </div>
        </div>
        <div class="s-ptb-40 s-main-container">
            <div class="s-content-tab">
                <router-link :to="$translate({name:'Expert Matching', params: {contentType: 'list'}})" class="s-text-none">
                    <div class="s-item" :class="tab == 'list'? 's-item-active' : ''">
                        <p>{{$t('expert.expertList')}}</p>
                    </div>
                </router-link>
                <router-link :to="$translate({name:'Expert Matching', params: {contentType: 'matching'}})" class="s-text-none">
                    <div class="s-item" :class="tab == 'matching'? 's-item-active' : ''" >
                        <p>{{$t('expert.expertMatching')}}</p>
                    </div>
                </router-link>
            </div>
            <template v-if="tab == 'matching'">
                <div>
                    <h3>{{$t('expert.availablePosition')}}</h3>
                </div>
                <div v-for="(i, index) in expertM" :key="`expert-${index}`" class="s-card-content s-plr-16 s-ptb-5 s-mb-16 s-cursor-pointer" @click.prevent="selectExpertM(i)">
                    <div class="s-center-flex s-wrap">
                        <div class="s-center-flex s-fullWidth">
                            <h5>{{i.title}}</h5>
                            <p class="s-mlr-16">|</p>
                            <div class="s-center-flex s-wrap">
                                <div v-for="(j, index) in i.expertise_detail" :key="`ed-${index}`" class="s-tag-secondary s-plr-10 s-mr-5 s-mb-5">
                                    <p class="s-text-white" style="font-size: 12px">{{j.name}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="s-column-2">
                            
                            <p class="s-mt--10">{{i.description}}</p>
                        </div>
                        <div class="s-column-2 s-center-flex">
                            <div class="s-center-flex s-column-3 s-line-clamp">
                                <img :src="require('@/assets/icons/primary/icon-map-pin.svg')">
                                <p>{{i.location}}</p>
                            </div>
                            <div class="s-flex s-column-2">
                                <div class="s-center-flex ">
                                    <img :src="require('@/assets/icons/primary/icon-calendar.svg')">
                                    <p>{{i.start_date}}</p>
                                </div>
                                <div>
                                    <p class="s-mlr-16">s/d</p>
                                </div>
                                <div class="s-center-flex ">
                                    <img :src="require('@/assets/icons/primary/icon-calendar.svg')">
                                    <p>{{i.end_date}}</p>
                                </div>
                            </div>
                            <div class="s-tag-success s-ml-auto s-ptb-5">
                                <p>{{i.status}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalExpertM v-if="modal == 'modalExpertM'" :data="selectedExpert"></ModalExpertM>
            </template>
            <template v-else>
                <div class="s-center-flex s-mtb-16">
                    <h3>{{$t('expert.ourExperts')}}</h3>
                    <div class="s-center-flex s-ml-auto">
                        <div class="s-fullWidth s-position-relative">
                            <div @click.prevent="menu = !menu">
                                <Input v-model="category1" :label="$t('expert.expertise')" :rightIcon="true" :readonly="true" :clickable="true" placeholder="Click here..." >
                                <template #rightIcon>
                                    <div class="s-square-16">
                                        <img class="s-fullWidth s-cursor-pointer" :src="require('@/assets/icons/primary/icon-arrow-down.svg')"/>
                                    </div>
                                </template>
                                </Input>
                            </div>
                            <div v-if="menu" @mouseleave="menu = false" class="s-position-absolute s-card s-fullWidth s-p-5 s-bg-white s-dropdown" style=" z-index: 100">
                                <div>
                                    <Input v-model="autocomplete">
                                    </Input>
                                </div>
                                <div class="s-card-item s-cursor-pointer s-plr-10 s-mt-10"  @click.prevent="category1 = 'Semua', menu = false, id = '', pushRoute()">
                                    <p>Semua</p>
                                </div>
                                <div class="s-card-item s-cursor-pointer s-plr-10" v-for="(i, index) in expertiseList" :key="`expertise-${index}`" @click.prevent="category1 = i.name, menu = false, id = i.id, pushRoute()">
                                    <p>{{i.name}}</p>
                                </div>
                            </div>
                        </div>
                        <Input :label="$t('expert.search')" v-model="search.type" class="s-ml-5 s-wd-300" :placeholder="$t('expert.keyword')" @keypress.enter="pushRoute()" :rightIcon="true">
						<template #rightIcon>
							<div class="s-square-20" @click.prevent="pushRoute()">
								<img class="s-cursor-pointer" :src="require('@/assets/icons/primary/icon-search.svg')">
							</div>
						</template>
						</Input>
                    </div>
                </div>
                <div>
                    <div v-if="expert.length == 0" class="s-fullWidth">
                        <div class="s-bg-secondary s-ptb-3 s-radius-10 s-fullWidth s-text-center">
                            <p class="s-weight-600 s-text-white">{{$t('expert.notfound')}}</p>
                        </div>
                    </div>
                    <div v-for="(i, index) in expert" :key="`expert-${index}`" class="s-card-content s-plr-16 s-ptb-5 s-mb-16 s-cursor-pointer" @click.prevent="selectExpert(i)">
                        <div class="s-center-flex s-wrap">
                            <div class="s-mr-16">
                                <div class=" s-square-80 s-radius-100 s-fullcenter-flex">
                                    <img v-if="!i.profile" :src="require('@/assets/image-profile.png')"/>
                                    <img v-else :src="i.profile"/>
                                </div>
                            </div>
                            <div class="">
                                <div class="s-center-flex">
                                    <h5>{{i.name}}</h5>
                                    <p class="s-mlr-16">|</p>
                                    <div v-for="(j, index) in i.expertises" :key="`ed-${index}`" class="s-tag-secondary s-plr-10 s-mr-5">
                                        <p class="s-text-white" style="font-size: 12px">{{j.name}}</p>
                                    </div>
                                </div>
                                <div class="s-flex s-mt--16">
                                    <div v-if="i.position" class="s-center-flex s-mr-16">
                                        <div class="s-square-30">
                                            <img :src="require('@/assets/icons/primary/icon-briefcase.svg')"/>
                                        </div>
                                        <p style="font-size: 12px">{{i.position}}</p>
                                    </div>
                                    <div v-if="i.rating != null" class="s-center-flex">
                                        <div class="s-square-30">
                                            <img :src="require('@/assets/icons/icon-star.svg')"/>
                                        </div>
                                        <p style="font-size: 12px">{{i.rating}} /10</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalExpert v-if="modal == 'modalExpert'" :data="selectedExpert"></ModalExpert>
            </template>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Input from '@/components/helper/Input'
import ModalExpert from '@/components/public/expertDetailModal'
import ModalExpertM from '@/components/public/expertMatchingModal'

export default {
    components: {
        Input,
        ModalExpert,
        ModalExpertM
    },
    data: () => ({
        search: {
            type: ''
        },
        menu: false,
        autocomplete: '',
        category1: '',
        id: '',
        selectedExpert: {}
    }),
    computed: {
        ...mapGetters({
            expertM: 'expertmatching/getExpertMatching',
            isMobile: 'getIsMobile',
            expert: 'expert/getExpert',
            expertise: 'expertise/getExpertise',
            modal: 'getModal'
        }),
        tab(){
            return this.$route.params.contentType
        },
        expertiseList(){
            if(!this.autocomplete) return this.expertise
            return this.expertise.filter(row => {
                return row.name.toLowerCase().includes(this.autocomplete.toLowerCase())
            })
        }
    },
    mounted(){
        this.initData()
    },
    watch: {
        $route(to, from){
            if(to != from){
                this.filterData()
            }
        }
    },
    methods: {
        ...mapActions({
            setExpertMatching: 'expertmatching/setExpertMatchingDataPublicData',
            setExpert: 'expert/setExpertPublicDropDown',
            setExpertise: 'expertise/setExpertisePublicData',
            setModal: 'setModal'
        }),
        async initData(){
            this.category1 = 'Semua'
            await this.setExpertMatching()
            await this.setExpert()
            await this.setExpertise()
            
        },
        formatDate(date){
            return this.commonDateTimeFormat(date)
        },
        selectExpert(data){
            this.selectedExpert = data
            this.setModal('modalExpert')
        },
        selectExpertM(data){
            this.selectedExpert = data
            this.setModal('modalExpertM')
        },
        async filterData(){
            let params = {}
            if(this.search.type){
                params = {
                    search: this.$route.query.search,
                }
            }
            if(this.id)
            {
                params = {
                    ...params,
                    filter: this.$route.query.id
                }
            }
            await this.setExpert(params)
        },
        pushRoute(){
            const query = {
                id: this.id,
                search: this.search.type
            }
            this.$router.push(this.$translate({name: 'Expert Matching', params: {contentType: 'list'}, query: query}))
                        .catch(error => {
							if (error.name != "NavigationDuplicated") throw error;
						})
        }
    }
}
</script>