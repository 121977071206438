<template>
    <Modal size="medium" :title="$t('expert.expertMatchingDetail')">
        <template #modalContent>
            <div>
                <h5>{{data.title}}</h5>
                <div class="s-center-flex s-wrap s-mt--16">
                     <div v-for="(j, index) in data.expertise_detail" :key="`ed-${index}`" class="s-tag-secondary s-plr-10 s-mr-5 s-mb-5">
                        <p class="s-text-white" style="font-size: 12px">{{j.name}}</p>
                    </div>
                </div>
                <div>
                    <p >{{data.description}}</p>
                </div>
                <div>
                    <div class="s-flex">
                        <div class="s-center-flex s-mr-16 s-column-2">
                            <img :src="require('@/assets/icons/primary/icon-map-pin.svg')">
                            <p>{{data.location}}</p>
                        </div>
                        <div class="s-column-2 s-flex">
                            <div class="s-center-flex">
                                <img :src="require('@/assets/icons/primary/icon-calendar.svg')">
                                <p>{{data.start_date}}</p>
                            </div>
                            <div class="s-mlr-16">
                                <p>s/d</p>
                            </div>
                            <div class="s-center-flex ">
                                <img :src="require('@/assets/icons/primary/icon-calendar.svg')">
                                <p>{{data.end_date}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="s-tag-success  s-ptb-5">
                        <p class="s-text-center s-fullWidth">{{data.status}}</p>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
import Modal from '@/components/helper/Modal'
export default {
    components: {
        Modal
    },
    props: {
        data: {
            default: {},
            required: true
        }
    }
}
</script>